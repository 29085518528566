<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <datatable
          ref="datatable"
          module="operasional"
          :title="title"
          :url="url"
          :columns="columns"
          :on-click-create="onClickCreate"
          :on-click-update="onClickUpdate"
          :on-click-delete="onClickDelete"
          :filter="filter"
          :disable-create="isDisableCreate"
        >
          <template v-slot:action>
            <v-btn
              type="button"
              color="primary"
              depressed
              small
              class="ml-auto mt-5 mr-2"
              :loading="loading"
              :disabled="loading"
              @click.prevent="openUploadDialog()"
            >
              <v-icon small left>mdi-upload</v-icon>
              Upload
            </v-btn>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ item.tanggal | moment("HH:mm") }}
          </template>
          <template v-slot:item.tanggal="{ item }">
            {{ item.tanggal | moment("dddd, Do MMMM YYYY") }}
          </template>
          <template v-slot:item.status_spinoam="{ item }">
            {{ item.status_spinoam == "1" ? "Valid" : "Expired" }}
          </template>
          <template v-slot:item.status_eblue="{ item }">
            {{ item.status_eblue == "1" ? "Valid" : "Expired" }}
          </template>
          <template v-slot:item.tipe_angkutan="{ item }">
            {{ getTipe(item.tipe_angkutan) }}
          </template>
          <template v-slot:item.jumlah_penumpang="{ item }">
            {{ item.jumlah_penumpang || 0 }}
          </template>
          <template v-slot:item.jumlah_penumpang_2="{ item }">
            {{ item.jumlah_penumpang_2 || 0 }}
          </template>
          <template v-slot:item.jumlah_penumpang_3="{ item }">
            {{ item.jumlah_penumpang_3 || 0 }}
          </template>
        </datatable>
      </div>
    </div>
    <form-upload ref="formUpload" :type="tipe"></form-upload>
    <form-operasional
      ref="form"
      module="operasional"
      :success="formSuccess"
      :tipe="tipe"
      :nama-petugas="namaPetugas"
    />
    <delete-dialog
      ref="delete"
      url="operasional"
      :success="deleteSuccess"
    ></delete-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Datatable from "@/view/component/Datatable.vue";
import DeleteDialog from "@/view/component/DeleteDialog.vue";
import FormOperasional from "./FormOperasional.vue";
import FormUpload from "./FormUpload.vue";
import { find } from "lodash";
export default {
  props: {
    tipe: String,
  },

  mounted() {
    if (!this.zoneId) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Operasional Terminal", route: "" },
        {
          title:
            this.tipe == "in"
              ? "Kendaraan Tiba"
              : this.tipe == "out"
              ? "Kendaraan Keluar"
              : this.tipe == "tiba"
              ? "Penumpang Tiba"
              : this.tipe == "turun"
              ? "Penumpang Turun"
              : this.tipe == "naik"
              ? "Penumpang Naik"
              : "Penumpang Berangkat",
        },
      ]);
    }

    this.namaPetugas = this.$store.getters.currentUser.name;
  },
  data() {
    return {
      namaPetugas: null,
      filterTipe: null,
      title:
        this.tipe == "in"
          ? "Kendaraan Tiba"
          : this.tipe == "out"
          ? "Kendaraan Keluar"
          : this.tipe == "tiba"
          ? "Penumpang Tiba"
          : this.tipe == "turun"
          ? "Penumpang Turun"
          : this.tipe == "naik"
          ? "Penumpang Naik"
          : "Penumpang Berangkat",
      url: "operasional/data",
      columns:
        ["in", "out"].indexOf(this.tipe) == -1
          ? [
              {
                value: "id",
              },
              {
                text: "Tanggal",
                value: "tanggal",
              },
              {
                text: "Jam",
                value: "created_at",
              },
              {
                text: "No. Kendaraan",
                value: "no_kendaraan",
                sortable: true,
                searchable: true,
              },
              {
                text: "Nama Perusahaan",
                value: "perusahaan",
                sortable: true,
                searchable: true,
              },
              {
                text: "Terminal Asal",
                value: "terminal",
                sortable: true,
                searchable: true,
              },
              {
                text: "Terminal Akhir",
                value: "terminal_tujuan",
                sortable: true,
                searchable: true,
              },
              (() => {
                return ["turun", "berangkat"].indexOf(this.tipe) != -1
                  ? {
                      text: "Jumlah Penumpang",
                      value: "jumlah_penumpang_2",
                      sortable: true,
                      searchable: true,
                    }
                  : this.tipe == "naik"
                  ? {
                      text: "Jumlah Penumpang",
                      value: "jumlah_penumpang_3",
                      sortable: true,
                      searchable: true,
                    }
                  : {
                      text: "Jumlah Penumpang",
                      value: "jumlah_penumpang",
                      sortable: true,
                      searchable: true,
                    };
              })(),
            ]
          : [
              {
                value: "id",
              },
              {
                text: "Tanggal",
                value: "tanggal",
              },
              {
                text: "Jam",
                value: "created_at",
              },
              {
                text: "Nama Petugas",
                value: "nama_petugas",
                sortable: true,
                searchable: true,
              },
              {
                text: "NIK",
                value: "nik",
                sortable: true,
                searchable: true,
              },
              {
                text: "No. Kendaraan",
                value: "no_kendaraan",
                sortable: true,
                searchable: true,
              },
              {
                text: "Perusahaan ID",
                value: "perusahaan_id",
                sortable: true,
                searchable: true,
              },
              {
                text: "Nama Perusahaan",
                value: "perusahaan",
                sortable: true,
                searchable: true,
              },
              {
                text: "Jenis Kendaraan",
                value: "jenis_kendaraan",
                sortable: true,
                searchable: true,
              },
              {
                text: "Status Spinoam",
                value: "status_spinoam",
                sortable: true,
                searchable: true,
              },
              {
                text: "Status E-blue",
                value: "status_eblue",
                sortable: true,
                searchable: true,
              },
              {
                text: "Jenis Angkutan",
                value: "tipe_angkutan",
                sortable: true,
                searchable: true,
              },
            ],
      filter: [
        {
          column: "tipe",
          value:
            ["tiba", "turun", "in", "naik"].indexOf(this.tipe) != -1
              ? "in"
              : "out",
          operator: "eq",
        },
      ],
      listTipe: [
        { id: "akap", text: "AKAP" },
        { id: "akdp", text: "AKDP" },
        { id: "pariwisata", text: "Pariwisata" },
      ],
    };
  },
  computed: {
    isDisableCreate() {
      return ["in", "out"].indexOf(this.tipe) == -1;
    },
  },
  methods: {
    onClickCreate() {
      this.$refs.form.open();
    },
    onClickUpdate(item) {
      this.$refs.form.open(item.id);
    },
    onClickDelete(item) {
      this.$refs.delete.open(item.id);
    },
    formSuccess() {
      this.$refs.datatable.getData();
    },
    deleteSuccess() {
      this.$refs.datatable.getData();
    },
    openUploadDialog() {
      this.$refs.formUpload.open();
    },
    getTipe(tipe) {
      return tipe ? find(this.listTipe, { id: tipe }).text : null;
    },
  },
  components: {
    Datatable,
    DeleteDialog,
    FormOperasional,
    FormUpload,
  },
};
</script>
