var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body"},[_c('datatable',{ref:"datatable",attrs:{"module":"operasional","title":_vm.title,"url":_vm.url,"columns":_vm.columns,"on-click-create":_vm.onClickCreate,"on-click-update":_vm.onClickUpdate,"on-click-delete":_vm.onClickDelete,"filter":_vm.filter,"disable-create":_vm.isDisableCreate},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"ml-auto mt-5 mr-2",attrs:{"type":"button","color":"primary","depressed":"","small":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.openUploadDialog()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-upload")]),_vm._v(" Upload ")],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.tanggal,"HH:mm"))+" ")]}},{key:"item.tanggal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.tanggal,"dddd, Do MMMM YYYY"))+" ")]}},{key:"item.status_spinoam",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_spinoam == "1" ? "Valid" : "Expired")+" ")]}},{key:"item.status_eblue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_eblue == "1" ? "Valid" : "Expired")+" ")]}},{key:"item.tipe_angkutan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTipe(item.tipe_angkutan))+" ")]}},{key:"item.jumlah_penumpang",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jumlah_penumpang || 0)+" ")]}},{key:"item.jumlah_penumpang_2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jumlah_penumpang_2 || 0)+" ")]}},{key:"item.jumlah_penumpang_3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jumlah_penumpang_3 || 0)+" ")]}}])})],1)]),_c('form-upload',{ref:"formUpload",attrs:{"type":_vm.tipe}}),_c('form-operasional',{ref:"form",attrs:{"module":"operasional","success":_vm.formSuccess,"tipe":_vm.tipe,"nama-petugas":_vm.namaPetugas}}),_c('delete-dialog',{ref:"delete",attrs:{"url":"operasional","success":_vm.deleteSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }