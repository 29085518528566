<template>
  <v-dialog v-model="opened" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline">
        Upload Dokumen
        <v-btn icon @click="close()" class="ml-auto">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                label="Upload Dokumen"
                placeholder="Pilih Dokumen"
                prepend-icon="mdi-paperclip"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div id="status-log">
                <span>Status Upload:</span><br />
                <span v-if="loading">Loading...</span>
                <div v-if="errors && errors.length > 0">
                  <div v-for="(err, idx) in errors" :key="idx">{{ err }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="12" align="end">
            <v-btn depressed class="ma-1" :disabled="loading" @click="close()">
              <v-icon small left>mdi-close</v-icon>
              Tutup
            </v-btn>
            <v-btn
              type="button"
              color="success"
              depressed
              class="ma-1"
              :loading="loading"
              :disabled="loading"
              @click.prevent="upload()"
            >
              <v-icon small left>mdi-upload</v-icon>
              Upload
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  props: {
    type: String,
  },
  data() {
    return {
      opened: false,
      file: null,
      loading: false,
      finish: false,
    };
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.loading = false;
      this.file = null;
      this.errors = [];
      this.finish = false;
    },
    async upload() {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", this.file);

      const { data } = await ApiService.upload(
        "operasional/upload/" + this.type,
        formData
      );
      if (data.error) {
        this.errors = data.error;
        this.loading = false;
      } else if (data.success) {
        this.loading = false;
        this.finish = true;
      }
    },
  },
};
</script>
<style scoped>
#status-log {
  border-radius: 10px;
  background-color: #eee;
  padding: 15px;
  margin: 10px;
}
</style>
