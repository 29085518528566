<template>
  <v-dialog v-model="opened" max-width="800" persistent>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="!canEdit"
      @submit.prevent="save()"
    >
      <v-card :loading="loadingData" :disabled="loadingData">
        <v-card-title class="headline">
          {{ !id ? "Tambah" : "Edit" }}
          {{
            this.tipe == "in"
              ? "Kendaraan Tiba"
              : this.tipe == "out"
              ? "Kendaraan Keluar"
              : this.tipe == "tiba"
              ? "Penumpang Tiba"
              : this.tipe == "turun"
              ? "Penumpang Turun"
              : this.tipe == "naik"
              ? "Penumpang Naik"
              : "Penumpang Berangkat"
          }}
          <v-btn icon @click="close()" class="ml-auto">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="data.zone_id"
                  :items="listZone"
                  :loading="isLoadingZone"
                  :search-input.sync="searchZone"
                  item-text="name"
                  item-value="id"
                  label="Terminal"
                  placeholder="Ketik nama Terminal"
                  outlined
                  clearable
                  dense
                  :disabled="!!zoneId || !isKendaraan"
                  :rules="[(v) => !!v || 'Terminal harus diisi']"
                ></v-autocomplete>
                <!-- <v-text-field
                  label="NIK Petugas"
                  dense
                  outlined
                  v-model="data.nik"
                ></v-text-field> -->
                <v-text-field
                  label="Nama Petugas"
                  dense
                  outlined
                  disabled
                  v-if="namaPetugas != null"
                  v-model="namaPetugas"
                ></v-text-field>

                <v-autocomplete
                  v-model="data.angkutan_id"
                  :items="listAngkutan"
                  :loading="isLoadingAngkutan"
                  :search-input.sync="searchAngkutan"
                  item-text="no_kendaraan"
                  item-value="id"
                  label="Kendaraan"
                  placeholder="Ketik nomor kendaraan"
                  outlined
                  clearable
                  dense
                  :disabled="!isKendaraan"
                  :rules="[(v) => !!v || 'Kendaraan harus diisi']"
                ></v-autocomplete>
                <v-text-field
                  label="ID PO Bus"
                  dense
                  outlined
                  disabled
                  :value="dataAngkutan ? dataAngkutan.id_pob : '-'"
                ></v-text-field>
                <v-text-field
                  label="PO Bus"
                  dense
                  outlined
                  disabled
                  :value="dataAngkutan ? dataAngkutan.nama_pob : '-'"
                ></v-text-field>
                <v-text-field
                  label="Trayek"
                  dense
                  outlined
                  disabled
                  :value="dataAngkutan ? dataAngkutan.nama_trayek : '-'"
                ></v-text-field>
                <v-text-field
                  label="Jenis Kendaraan"
                  dense
                  outlined
                  disabled
                  :value="dataAngkutan ? dataAngkutan.jenis_kendaraan : '-'"
                ></v-text-field>
                <v-text-field
                  label="Terminal Asal"
                  dense
                  outlined
                  disabled
                  :value="
                    dataAngkutan &&
                    dataAngkutan.terminal &&
                    dataAngkutan.terminal[0]
                      ? dataAngkutan.terminal[0]
                      : '-'
                  "
                ></v-text-field>
                <v-autocomplete
                  v-model="data.zone_id_tujuan"
                  :items="listZoneTujuan"
                  :loading="isLoadingZoneTujuan"
                  :search-input.sync="searchZoneTujuan"
                  item-text="name"
                  item-value="id"
                  label="Terminal Tujuan"
                  placeholder="Ketik nama Terminal"
                  outlined
                  clearable
                  dense
                  :disabled="!isKendaraan"
                ></v-autocomplete>
                <!-- <v-text-field
                  label="Terminal Tujuan"
                  dense
                  outlined
                  disabled
                  :value="
                    dataAngkutan &&
                    dataAngkutan.terminal &&
                    dataAngkutan.terminal.length > 1
                      ? dataAngkutan.terminal[dataAngkutan.terminal.length - 1]
                      : '-'
                  "
                ></v-text-field> -->
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="isKendaraan"
                  label="Jenis Angkutan"
                  dense
                  outlined
                  disabled
                  :value="jenisAngkutan"
                ></v-text-field>
                <v-text-field
                  v-if="!isKendaraan && !isJumlah2 && !isJumlah3"
                  label="Jumlah Penumpang"
                  dense
                  outlined
                  type="number"
                  v-model="data.jumlah_penumpang"
                ></v-text-field>
                <v-text-field
                  v-if="!isKendaraan && isJumlah2"
                  label="Jumlah Penumpang"
                  dense
                  outlined
                  type="number"
                  v-model="data.jumlah_penumpang_2"
                ></v-text-field>
                <v-text-field
                  v-if="!isKendaraan && isJumlah3"
                  label="Jumlah Penumpang"
                  dense
                  outlined
                  type="number"
                  v-model="data.jumlah_penumpang_3"
                ></v-text-field>
                <!-- Start Date Kendaraan naik -->
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  max-width="290"
                  v-if="!isKendaraan && isJumlah3"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Tanggal Penumpang Naik"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      :value="computedDateFormattedMomentjsNaik"
                      @click:clear="data.tanggal_penumpang_naik = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.tanggal_penumpang_naik"
                    scrollable
                    @change="menu3 = false"
                  >
                  </v-date-picker>
                </v-menu>
                <v-menu
                  ref="menu4"
                  v-if="!isKendaraan && isJumlah3"
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time_penumpang_naik"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time_penumpang_naik"
                      label="Jam Penumpang Naik"
                      prepend-icon="mdi-clock"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu4"
                    v-model="time_penumpang_naik"
                    full-width
                    @click:minute="$refs.menu4.save(time_penumpang_naik)"
                  ></v-time-picker>
                </v-menu>
                <!-- End Date Kendaraan Naik -->
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="!isKendaraan"
                      label="Tanggal"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      :value="computedDateFormattedMomentjs"
                      @click:clear="data.tanggal = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.tanggal"
                    scrollable
                    @change="menu1 = false"
                  >
                  </v-date-picker>
                </v-menu>
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      label="Jam"
                      prepend-icon="mdi-clock"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      :disabled="!isKendaraan"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
                <v-radio-group
                  v-if="isKendaraan"
                  v-model="data.status_spinoam"
                  row
                >
                  <template v-slot:label>
                    <div>Status Spinoam</div>
                  </template>
                  <v-radio label="Valid" :value="1"></v-radio>
                  <v-radio label="Expired" :value="0"></v-radio>
                </v-radio-group>
                <v-text-field
                  v-if="isKendaraan"
                  label="Tanggal Kadaluarsa Uji"
                  dense
                  outlined
                  disabled
                  :value="
                    dataAngkutan
                      ? formatTgl(dataAngkutan.tgl_kadaluarsa_uji)
                      : '-'
                  "
                ></v-text-field>
                <v-text-field
                  v-if="isKendaraan"
                  label="Tanggal Kadaluarsa KPS"
                  dense
                  outlined
                  disabled
                  :value="
                    dataAngkutan
                      ? formatTgl(dataAngkutan.tgl_kadaluarsa_kps)
                      : '-'
                  "
                ></v-text-field>
                <v-radio-group
                  v-if="isKendaraan"
                  v-model="data.status_eblue"
                  row
                >
                  <template v-slot:label>
                    <div>Status E-blue</div>
                  </template>
                  <v-radio label="Valid" :value="1"></v-radio>
                  <v-radio label="Expired" :value="0"></v-radio>
                </v-radio-group>
                <v-text-field
                  v-if="isKendaraan"
                  label="Masa Berlaku"
                  dense
                  outlined
                  disabled
                  :value="
                    dataAngkutan ? formatTgl(dataAngkutan.masa_berlaku) : '-'
                  "
                ></v-text-field>
                <v-textarea
                  v-model="data.note"
                  label="Catatan"
                  dense
                  outlined
                  :disabled="!isKendaraan"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" align="end">
              <v-btn
                depressed
                class="ma-1"
                :disabled="loading"
                @click="close()"
              >
                <v-icon small left>mdi-close</v-icon>
                Tutup
              </v-btn>
              <v-btn
                v-if="canEdit"
                type="submit"
                color="success"
                depressed
                class="ma-1"
                :loading="loading"
                :disabled="loading"
                @click.prevent="save()"
              >
                <v-icon small left>mdi-content-save</v-icon>
                Simpan
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import moment from "moment";
import { find } from "lodash";
let timeout = null;
let timeoutTujuan = null;
let timeoutAngkutan = null;
export default {
  mounted() {
    // setTimeout(() => {
    //   this.namaPetugas = this.$store.getters.currentUser.name;
    //   console.log(this.namaPetugas);
    // }, 200);

    const isAdmin = this.$store.getters.hasPermission(this.module, "admin");

    if (!isAdmin) {
      this.zoneId = this.$store.getters.currentUser.activeZone.id;
    }
  },
  data() {
    return {
      action: "create",
      id: null,
      opened: false,
      loading: false,
      loadingData: false,
      valid: true,
      listZone: [],
      isLoadingZone: false,
      searchZone: "",
      listZoneTujuan: [],
      isLoadingZoneTujuan: false,
      searchZoneTujuan: "",
      listAngkutan: [],
      isLoadingAngkutan: false,
      searchAngkutan: "",
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      time: null,
      time_penumpang_naik: null,
      zoneId: null,
      listTipe: [
        { id: "akap", text: "AKAP" },
        { id: "akdp", text: "AKDP" },
        { id: "pariwisata", text: "Pariwisata" },
      ],
      data: {
        zone_id: "",
        zone_id_tujuan: "",
        tanggal: null,
        tanggal_penumpang_naik: null,
        angkutan_id: "",
        jumlah_penumpang: "",
        jumlah_penumpang_2: "",
        jumlah_penumpang_3: "",
        status_spinoam: null,
        status_eblue: null,
        note: "",
        tipe: "",
      },
      rules: {
        name: [
          (v) => !!v || "Nama harus diisi",
          (v) =>
            (v && v.length <= 150) || "Nama harus kurang dari 150 karakter",
        ],
      },
    };
  },
  props: {
    tipe: String,
    module: {
      type: String,
    },
    success: {
      type: Function,
      default: function() {},
    },
    namaPetugas: {
      type: String,
    },
  },
  methods: {
    open(id) {
      this.opened = true;
      setTimeout(() => {
        this.$refs.form.reset();

        if (id) {
          this.getData(id);
        } else {
          if (this.zoneId) {
            this.data.zone_id = this.zoneId;
            this.getListZone(this.data.zone_id, true);
          }

          this.data.status_eblue = 1;
          this.data.status_spinoam = 1;
        }
        setTimeout(() => {
          this.namaPetugas = this.$store.getters.currentUser.name;
        }, 100);

        // console.log(this.namaPetugas);
      }, 1);
    },
    async getListAngkutan(val, isID) {
      this.isLoadingAngkutan = "warning";
      const params = {
        columns: [
          { data: "id", searchable: true },
          { data: "no_kendaraan", searchable: true },
          { data: "nama_trayek", searchable: false },
          { data: "id_pob", searchable: false },
          { data: "nama_pob", searchable: false },
          { data: "terminal", searchable: false },
          { data: "jenis_kendaraan", searchable: false },
          { data: "tipe", searchable: false },
          { data: "tgl_kadaluarsa_uji", searchable: false },
          { data: "tgl_kadaluarsa_kps", searchable: false },
          { data: "masa_berlaku", searchable: false },
        ],
        perPage: 10,
        search: { value: val },
      };
      if (isID) {
        params.filter = [
          {
            column: "id",
            value: val,
          },
        ];

        delete params.search;
      }

      const { data } = await ApiService.post("angkutan/data", params);

      this.listAngkutan = data.data;
      this.isLoadingAngkutan = false;
    },
    async doGetListZone(val, isID) {
      const params = {
        filter: [
          {
            column: "level",
            value: "terminal",
          },
        ],
        columns: [
          { data: "id", searchable: false },
          { data: "name", searchable: true },
        ],
        perPage: 10,
        search: { value: val },
      };

      if (isID) {
        params.filter = [
          {
            column: "id",
            value: val,
          },
        ];

        delete params.search;
      }

      const { data } = await ApiService.post("zone/data", params);

      return data.data;
    },
    async getListZone(val, isID) {
      this.isLoadingZone = "warning";
      const data = await this.doGetListZone(val, isID);

      this.listZone = data;
      this.isLoadingZone = false;
    },
    async getListZoneTujuan(val, isID) {
      this.isLoadingZoneTujuan = "warning";
      const data = await this.doGetListZone(val, isID);

      this.listZoneTujuan = data;
      this.isLoadingZoneTujuan = false;
    },
    async getData(id) {
      this.loadingData = true;
      this.id = id;
      ApiService.get("operasional?id=" + id)
        .then(({ data }) => {
          this.data = {
            zone_id: data.zone_id,
            zone_id_tujuan: data.zone_id_tujuan,
            tanggal: data.tanggal,
            tanggal_penumpang_naik: data.tanggal_penumpang_naik,
            angkutan_id: data.angkutan_id,
            jumlah_penumpang: data.jumlah_penumpang,
            jumlah_penumpang_2: data.jumlah_penumpang_2,
            jumlah_penumpang_3: data.jumlah_penumpang_3,
            status_spinoam: data.status_spinoam,
            status_eblue: data.status_eblue,
            note: data.note,
            tipe: data.tipe,
          };
          this.loadingData = false;
          if (this.data.zone_id) {
            this.getListZone(data.zone_id, true);
          }

          if (this.data.zone_id_tujuan) {
            this.getListZoneTujuan(data.zone_id_tujuan, true);
          }
          if (this.data.angkutan_id) {
            this.getListAngkutan(data.angkutan_id, true);
          }

          if (data.tanggal) {
            this.time = moment(this.data.tanggal).format("HH:mm");
            this.data.tanggal = moment(this.data.tanggal).format("YYYY-MM-DD");
          }
          if (data.tanggal_penumpang_naik) {
            this.time_penumpang_naik = moment(
              this.data.tanggal_penumpang_naik
            ).format("HH:mm");
            this.data.tanggal_penumpang_naik = moment(
              this.data.tanggal_penumpang_naik
            ).format("YYYY-MM-DD");
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.handleError(response.data);
          }
          this.loadingData = false;
        });
    },
    async save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        const sdm = !this.id ? "post" : "update";
        let url = "operasional";
        if (this.id) {
          url = url + "?id=" + this.id;
        } else {
          this.data.tipe = this.tipe;
        }

        this.data.tanggal += " " + this.time;
        if (this.isJumlah3 && !this.isKendaraan) {
          this.data.tanggal_penumpang_naik += " " + this.time_penumpang_naik;
        }

        ApiService[sdm](url, this.data)
          .then(({ data }) => {
            if (data.id) {
              this.loading = false;
              this.success(data);
              this.$bvToast.toast("Records has been saved!", {
                title: "Saved",
                variant: "success",
                solid: true,
              });
              this.close();
            }
          })
          .catch(({ response }) => {
            if (response.data) {
              this.handleError(response.data);
            }

            this.loading = false;
          });
      }
    },
    handleError(data) {
      if (data.error) {
        this.$bvToast.toast(data.error, {
          title: "Oops",
          variant: "danger",
          solid: true,
        });
      }
    },
    close() {
      this.opened = false;
      this.id = null;
      this.listZone = [];
      this.isLoadingZone = false;
      this.data.tanggal = null;
      this.data.tanggal_penumpang_naik = null;
      this.$refs.form.reset();
      this.search = "";
      this.listAngkutan = [];
      this.isLoadingAngkutan = false;
      this.searchAngkutan = "";
      this.listZoneTujuan = [];
      this.isLoadingZoneTujuan = false;
      this.searchZoneTujuan = "";
    },
    formatTgl(tgl) {
      return moment(tgl).format("Do MMMM YYYY");
    },
  },
  watch: {
    searchZone(val) {
      if (!val) {
        return;
      }
      clearTimeout(timeout);

      timeout = setTimeout(async () => {
        this.getListZone(val, false);
      }, 200);
    },
    searchZoneTujuan(val) {
      if (!val) {
        return;
      }
      clearTimeout(timeoutTujuan);

      timeoutTujuan = setTimeout(async () => {
        this.getListZoneTujuan(val, false);
      }, 200);
    },
    searchAngkutan(val) {
      if (!val) {
        return;
      }
      clearTimeout(timeoutAngkutan);

      timeoutAngkutan = setTimeout(async () => {
        this.getListAngkutan(val, false);
      }, 200);
    },
  },
  computed: {
    isKendaraan() {
      return ["in", "out"].indexOf(this.tipe) != -1;
    },
    isJumlah2() {
      return ["turun", "berangkat"].indexOf(this.tipe) != -1;
    },
    isJumlah3() {
      return this.tipe == "naik";
    },
    canEdit() {
      if (
        this.id &&
        !this.$store.getters.hasPermission(this.module, "update")
      ) {
        return false;
      }

      return true;
    },
    // userInfo() {
    //   return this.$store.getters.currentUser.activeZone;
    // },
    computedDateFormattedMomentjs() {
      return this.data.tanggal
        ? moment(this.data.tanggal).format("dddd, Do MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjsNaik() {
      return this.data.tanggal_penumpang_naik
        ? moment(this.data.tanggal_penumpang_naik).format("dddd, Do MMMM YYYY")
        : "";
    },
    jenisAngkutan() {
      if (this.dataAngkutan && this.dataAngkutan.tipe) {
        return find(this.listTipe, { id: this.dataAngkutan.tipe }).text;
      } else {
        return "-";
      }
    },
    dataAngkutan() {
      const data = this.listAngkutan.filter(
        (v) => v.id == this.data.angkutan_id
      );
      if (data) {
        return data[0];
      } else {
        return {
          id: null,
          no_kendaraan: null,
          nama_trayek: null,
          nama_pob: null,
          tipe: null,
        };
      }
    },
  },
};
</script>
